import { HttpHeaders } from '@angular/common/http';

export const headers = () => {

  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    "X-Requested-With": '*',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with',
    'Access-Control-Expose-Headers': '*',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.access_token,
    'F-Token': (Math.floor(Math.random() * 10000) + 1).toString()
  };

  return new HttpHeaders(headers);
};

export const ediHeaders = (apiKey: string) => {

  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
    'Cache-Control': 'no-cache',
    'X-Profrakt-Key': apiKey
  };

  return new HttpHeaders(headers);
};

export const logout_headers = () => {
  const headers: any = {
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.refresh_token,
    'F-Token': (Math.floor(Math.random() * 10000) + 1).toString()
  };

  return new HttpHeaders(headers);
};

export const refresh_token_headers = () => {
  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    "X-Requested-With": '*',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with',
    'Access-Control-Expose-Headers': '*',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.refresh_token,
    'F-Token': (Math.floor(Math.random() * 10000) + 1).toString()
  };

  return new HttpHeaders(headers);
};

export const productHeaders = () => {

  const headers: any = {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization, X-Request-With',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.access_token,
    'Admin-Panel-Store-Id': localStorage.getItem('store_id') ? localStorage.getItem('store_id') : ''
  };

  return new HttpHeaders(headers);
};

export const imageHeaders = () => {

  const headers: any = {
    "Accept": "*/*",
    'Admin-Panel-Store-Id': localStorage.getItem('store_id') ? localStorage.getItem('store_id') : '',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.access_token,
  };

  return new HttpHeaders(headers);
};

export const fileHeaders = () => {

  const headers: any = {
    'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, OPTIONS',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.access_token,
    'F-Token': (Math.floor(Math.random() * 10000) + 1).toString()
  };

  return new HttpHeaders(headers);
};


export const xuserdataHeaders = () => {

  const headers: any = {
    'x-user-data': 'eyJ1c2VyX2lkIjogOCwgInBlcm1pc3Npb25zIjogeyIxIjogMX0sICJpc19hZG1pbiI6IHRydWV9',
  };

  return new HttpHeaders(headers);
};

export const ngRokHeaders = () => {

  const headers: any = {
    'Content-Type': 'application/json',
    'Accept': '*/*',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')!)?.access_token,
  }

  return new HttpHeaders(headers);
}

// {"user_id": 1, "permissions": {"1": 1}, "is_admin": true} - eyJ1c2VyX2lkIjogOCwgInBlcm1pc3Npb25zIjogeyIxIjogMX0sICJpc19hZG1pbiI6IHRydWV9
// {"user_id": 1499, "permissions": {"1": 4}, "is_admin": false} - eyJ1c2VyX2lkIjogMTQ5OSwgInBlcm1pc3Npb25zIjogeyIxIjogNH0sICJpc19hZG1pbiI6IGZhbHNlfQ==
// {"user_id": 1474, "permissions": {"1": 1}, "is_admin": false} - eyJ1c2VyX2lkIjogMTQ3NCwgInBlcm1pc3Npb25zIjogeyIxIjogMX0sICJpc19hZG1pbiI6IGZhbHNlfQ==
// {"user_id": 1, "permissions": {"1": 1}, "is_admin": true} - eyJ1c2VyX2lkIjogMSwgInBlcm1pc3Npb25zIjogeyIxIjogMX0sICJpc19hZG1pbiI6IHRydWV9
// {"user_id": 1682, "permissions": {"1": 3}, "is_admin": false} - eyJ1c2VyX2lkIjogMTY4MiwgInBlcm1pc3Npb25zIjogeyIxIjogM30sICJpc19hZG1pbiI6IGZhbHNlfQ==