import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { fileHeaders, headers, ngRokHeaders } from '../../shared/helpers/headers.helper';
import { Product } from 'src/app/modules/shipments/interfaces/shipment-product.interface';
import { EdiAgreementInterface } from '../../modules/shipments/interfaces/edi-agreement.interface';
import { NewShipmentEstimatePayloadInterface, ShipmentEstimatePayloadInterface } from '../../modules/shipments/interfaces/shipment-estimate-payload.interface';
import { EdiPickupPoint } from '../../modules/shipments/interfaces/edi-pickup-point';
import { BringAddresses } from './interfaces/braing-addresses.namespace';
import { CreateShipmentRequestInterface } from 'src/app/modules/shipments/interfaces/create-shipment-request.interface';
import { ShipmentEstimateServerResponseInterface } from 'src/app/modules/shipments/interfaces/shipment-estimate-server-response.interface';
import { DefaultResponse } from '../shared/interfaces/responses.interface';
import { ShipmentsListResponseInterface } from './interfaces/shipments-list-response.interface';
import { ShipmentDetailsInterface } from 'src/app/modules/shipments/new-interfaces/shipment-details.interface';

@Injectable({
  providedIn: 'root'
})

export class ShipmentsService {
  private url = environment.baseUrl + '/profrakt';
  private ngrok_url = 'https://d662-193-28-84-6.ngrok-free.app';

  constructor(private http: HttpClient) {}

  getShipments(itemsPerPage: number,
               activePage: number,
               search?: string,
               client?: string | number | null,
               ordering?: string,
               startDate?: any,
               endDate?: any,
               dateType?: 'received_date' | 'created_at' | string | null,
               typeFilter?: any,
               serviceFilter?: 'bring' | 'postnord' | null,
               products?: string[] | null,
               invoiceNumbers?: string[] | null,
               profitRangeFilter?: { profit_min: number, profit_max: number} | null,
               priceRangeFilter?: { min: number, max: number } | null,
               extra_payment?: string[] | null,
               managership_id?: number | null): Observable<ShipmentsListResponseInterface> {
    let productsQuery = products?.length ?  `&product_id=${ products.join('&product_id=') }` : '';
    let extra_paymentQuery = extra_payment?.length ?  `&extra_payment=${ extra_payment.join('&extra_payment=') }` : '';
    let invoiceNumbersQuery = invoiceNumbers?.length ?  `&invoice_id=${ invoiceNumbers.join('&invoice_number=') }` : ''
    let dateQuery = startDate && endDate && dateType ? `&${ dateType }_from=${ startDate }&${ dateType }_to=${ endDate }` : '';
    
    let request = `${this.url}/shipments/?page_size=${ itemsPerPage }&page=${ activePage }${
                                                       search ? `&search=${ search }` : '' }${
                                                       client ? `&company_id=${ client }` : '' }${
                                                       ordering ? `&ordering=${ ordering }` : '' }${
                                                       dateQuery ? dateQuery : '' }${
                                                       typeFilter ? `&type=${ typeFilter }` : ''}${
                                                       serviceFilter ? `&carrier_id=${ serviceFilter }` : ''}${
                                                       productsQuery }${ invoiceNumbersQuery }${
                                                       profitRangeFilter?.profit_min || profitRangeFilter?.profit_min == 0 ? `&profit_from=${ profitRangeFilter.profit_min }` : '' }${
                                                       profitRangeFilter?.profit_max || profitRangeFilter?.profit_max == 0 ? `&profit_to=${ profitRangeFilter.profit_max }` : '' }${
                                                       priceRangeFilter && (priceRangeFilter.min || priceRangeFilter!.min == 0) ? `&client_price_from=${ priceRangeFilter.min }` : '' }${
                                                       priceRangeFilter && (priceRangeFilter.max || priceRangeFilter!.max == 0) ? `&client_price_to=${ priceRangeFilter.max }` : '' }${
                                                       extra_paymentQuery}${managership_id ? `&managership_id=${ managership_id }` : '' }`;
    return this.http.get<ShipmentsListResponseInterface>(request, { headers: headers() });
  }

  getShipmentDetails(id: string): Observable<ShipmentDetailsInterface | any> {
    return this.http.get<ShipmentDetailsInterface | any>(`${this.url}/shipments/${ id }/`, { headers: headers()});
  }

  getShipmentDetailsNew(id: string): Observable<ShipmentDetailsInterface> {
    return this.http.get<ShipmentDetailsInterface>(`${this.url}/shipments/${ id }/`, { headers: headers()});
  }

  getShipmentProducts(): Observable<Product[] | any> {
    return this.http.get(`${this.url}/shipments/products/`, { headers: headers()});
  }

  getExtraPayments() {
    return this.http.get(`${this.url}/shipments/extra_payments/`, { headers: headers()});
  }

  downloadShipments(search?: string | null,
                    client?: string | number | null,
                    startDate?: any,
                    endDate?: any,
                    dateType?: 'date' | 'created' | string | null,
                    typeFilter?: any,
                    products?: string[] | null,
                    shipments_ids?: string[] | null,
                    invoiceNumbers?: string[] | null,
                    profitRangeFilter?: { profit_min: number, profit_max: number} | null,
                    priceRangeFilter?: { min: number, max: number } | null,
                    extra_payment?: string[] | null,
                    extra_params?: string,
                    managership_id?: number | null): Observable<Blob> {
    let productsQuery = products?.length ?  `&product=${ products.join('&product=') }` : '';
    let shipmentsIDsQuery = shipments_ids?.length ?  `&shipment_id=${ shipments_ids.join('&shipment_id=') }` : '';
    let extra_paymentQuery = extra_payment?.length ?  `&extra_payment=${ extra_payment.join('&extra_payment=') }` : '';
    let invoiceNumbersQuery = invoiceNumbers?.length ?  `&invoice_number=${ invoiceNumbers.join('&invoice_number=') }` : '';
    let dateQuery = startDate && endDate && dateType ? `&${ dateType }__gte=${ startDate }&${ dateType }__lte=${ endDate }` : '';

    let request = `${this.url}/shipments/download/?${ search ? `search=${ search }&` : '' }${
                                                      client ? `client=${ client }&` : '' }${
                                                      dateQuery ? dateQuery : '' }${
                                                      typeFilter ? `&type=${ typeFilter }` : ''}${
                                                      productsQuery }${ shipmentsIDsQuery }${ invoiceNumbersQuery }${
                                                      profitRangeFilter?.profit_min || profitRangeFilter?.profit_min == 0 ? `&profit__gte=${ profitRangeFilter.profit_min }` : '' }${
                                                      profitRangeFilter?.profit_max || profitRangeFilter?.profit_max == 0 ? `&profit__lte=${ profitRangeFilter.profit_max }` : '' }${
                                                      priceRangeFilter && (priceRangeFilter.min || priceRangeFilter!.min == 0) ? `&client_price__gte=${ priceRangeFilter.min }` : '' }${
                                                      priceRangeFilter && (priceRangeFilter.max || priceRangeFilter!.max == 0) ? `&client_price__lte=${ priceRangeFilter.max }` : '' }${
                                                      extra_paymentQuery }&extra_params=${ extra_params }${ managership_id ? 'managership_id=' + managership_id : '' }`;

    return this.http.get(request, { headers: headers(), responseType: 'blob'});
  }

  importPostnordShipments(body: FormData): Observable<null> {
    return this.http.post<null>(`${this.url}/shipments/upload_postnord_packages/`, body, { headers: fileHeaders() });
  }

  getEDIAgreements(clientID: number | string, managershipID: number): Observable<EdiAgreementInterface[]> {
    return this.http.get<EdiAgreementInterface[]>(`${this.url}/client/${clientID}/edi-agreements/?sender=${managershipID}`, { headers: headers()})
  }

  getEDIPickupPoints(clientID: number | string, 
                     country: string, 
                     postcode: string, 
                     carrier: string, 
                     product: string, 
                     address: string, 
                     city: string): Observable<EdiPickupPoint[]> {
    return this.http.get<EdiPickupPoint[]>(
      `${this.url}/client/${clientID}/edi-pickup-points/?country=${country}&postcode=${postcode}&carrier=${carrier}&product=${product}&address=${address}&city=${city}`, { headers: headers()})
  }

  getShipmentEstimateInfo(body: ShipmentEstimatePayloadInterface | NewShipmentEstimatePayloadInterface): Observable<ShipmentEstimateServerResponseInterface> {
    return this.http.post<ShipmentEstimateServerResponseInterface>(`${this.url}/shipment/calculate-cost/`, body, { headers: headers()})
  }

  // any type will be refactor later !!!!!!!
  createShipment(body: CreateShipmentRequestInterface | any): Observable<{shipment_number: string, consignment_pdf: string}> {
    return this.http.post<{shipment_number: string, consignment_pdf: string}>(`${this.url}/shipment/`, body, { headers: headers()})
  }
  // any type will be refactor later !!!!!!!

  getAddressesByPostalCode(postalCode: string): Observable<BringAddresses.GetAddressesByPostalCodeResponse> {
    return this.http.get<BringAddresses.GetAddressesByPostalCodeResponse>(`${this.url}/shipment/get-addresses/?country_code=no&postal_code=${ postalCode }`, { headers: headers()})
  }

  getAddressSuggestion(search: string): Observable<BringAddresses.GetAddressSuggestionResponse> {
    return this.http.get<BringAddresses.GetAddressSuggestionResponse>(`${this.url}/shipment/get-address-suggestion/?country_code=no&q=${ search }`, { headers: headers()})
  }

  printLabel(body: {client: number | string, consignment_id: number, printer_id: number, sender: number}): Observable<null> {
    return this.http.post<null>(`${this.url}/shipment/print_label/`, body, { headers: headers()})
  }

  removeShipments(shipment_ids: string[], company_id: number | string): Observable<DefaultResponse> {
    return this.http.patch<DefaultResponse>(`${this.url}/shipments/remove/`, { shipment_ids, company_id }, { headers: headers()})
  }

  revealShipments(shipment_ids: string[], company_id: number | string): Observable<DefaultResponse> {
    return this.http.patch<DefaultResponse>(`${this.url}/shipments/reveal/`, { shipment_ids, company_id }, { headers: headers()})
  }

  //TODO: this is test url, to remove after testing performance
  testBigQueryGetShipments(page: number, size: number): Observable<any> {
    const url = `https://devkokkeglede.adminpanel.no/api/test/big-query?page=${page}&size=${size}`
    return this.http.get(url, {headers: headers()})
  }
}
